<template>
 <div>
        <Header />
        <br /><br /><br /><br /><br /><br /><br />
        <div class="container fluid">
          <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/idc_solutions/mic25">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/06/MIC-25-CON-PENNELLATE-1024x696.png"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                MIC 25
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/idc_solutions/easys">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/06/Easy-S-tre-quarti-rev.2-210609-DEF-1024x696.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Easy S
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/idc_solutions/intercons">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/07/Intecon-S_re1-frontale-210614-DEF-1536x1044.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Intercon S
              </h4>
            </div>
          </div>
          </router-link>
        </div>
          <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/idc_solutions/interconxh">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/06/Intecon-XH-tre-quarti-Rev.2-210609-DEF-1536x1044.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
              Intercon XH
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/idc_solutions/sharkxh">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/06/Shark-XH-rev.1-210607-1536x1044.png"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               Shark XH
              </h4>
            </div>
          </div>
          </router-link>
        </div>
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/idc_solutions/chain">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/06/Chain-tre-quarti-rev.1-210610-1536x1044.jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               Chain
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        
        </div>
      </div>
        <Footer />
    </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>